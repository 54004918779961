import type { Article } from "../../types/content";

import { getPulseTracker } from "..";
import { getInfoCardObject } from "../helpers/getInfoCardObject";
import { getTeaserTarget } from "../helpers/getTeaserTarget";

const trackInfoCardClick = ({ article }: { article: Article }) => {
  const pulseTracker = getPulseTracker();
  const baseUrl = pulseTracker?.pulseTracker.builders.origin?.url || "";
  pulseTracker?.executePulseCommand("track", "engagementEvent", {
    action: "Click",
    object: getInfoCardObject({
      article,
    }),
    target: getTeaserTarget(article, baseUrl),
  });
};

export { trackInfoCardClick };
