import type { Article } from "../../types/content";

const getInfoCardObject = ({ article }: { article: Article }) => {
  return {
    id: `infoCard-${article.article_id}`,
    type: "UIElement",
    elementType: "InfoCard",
    name: article.teaser.title.value,
    "spt:custom": {
      is_sponsored: article.meta?.is_sponsored,
      sponsor: article.sponsor,
      vignette: article.story_vignette?.title,
      story: article.story?.title,
      category: article.category.title,
    },
  };
};

export { getInfoCardObject };
