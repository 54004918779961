import type { OriginData } from "../../types/pulse";

const stripSlug = (url: string | undefined): string | undefined => {
  if (!url) return;

  const regex = /^(https:\/\/[^/]+)\/[^/]+(\/a\/\w{6})$/;
  const result = url.replace(regex, "$1$2");

  return result;
};

const getPageUrl = (page: OriginData): string | undefined => {
  if (page.type === "Article") {
    return stripSlug(page.url);
  }

  return page.url;
};

export { getPageUrl };
